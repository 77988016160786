<template>
	<div class="search_result">
		<div class="top">
			<div class="title" @click="backClick">
				{{$t('other.homePage')}}
			</div>
			<div class="img">
				<img src="../../../assets/home_icons/front.png">
			</div>
			<div class="sub_title">{{search_name?search_name:$t('other.all')}}</div>
		</div>
		<div class="all_house" v-if="searchList.length>0">
			<houseItem2 v-for="(item,index) in searchList" :key="index" :houseItem2="item">
			</houseItem2>
		</div>
		<div class="empty" v-else>
			<el-empty :image-size="200" :description="$t('noData')">
			</el-empty>
		</div>
		<div class="pagination">
			<el-pagination background layout="prev, pager, next,sizes" :total="total"
				@current-change="currentChangeClick" @size-change="sizeChangeClick" :current-page="currentPage"
				:page-size="pageSize" :background="true" popper-class="select_bottom" :page-sizes="pageSizes">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import houseItem2 from '@/components/houseItem2.vue'

	export default {
		components: {
			houseItem2
		},
		data() {
			return {
				search_name: '', //搜索关键词
				searchList: [], //搜索列表

				total: 8, //总条数
				currentPage: 1, //当前页数
				pageSize: 8, //每页的条数
				pageSizes: [8, 10, 20, 30, 40, 50, 100],
			}
		},
		watch: {
			// 监听路由参数的变化
			$route() {
				this.search_name = this.$route.query.search_name;
				this.getSearchResult();
			},
		},

		mounted() {
			this.search_name = this.$route.query.search_name;
			this.getSearchResult();
		},

		methods: {
			backClick() {
				this.$router.push('/home');
			},
			//获取搜索列表
			getSearchResult() {
				let data = {
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					search_name: this.search_name,
					// user_id: this.user_id,
				};
				this.$http.searchResult(data).then(res => {
					if (res.code == 1) {
						this.searchList = res.data.data;
						this.total = res.data.total;
					} else {
						this.$message.error(res.msg);
					}
				})
			},

			currentChangeClick(currentPage) {
				this.currentPage = currentPage;
				this.getSearchResult();

			},
			sizeChangeClick(pageSize) {
				this.pageSize = pageSize;
				this.getSearchResult();
			},
		}
	}
</script>

<style scoped="" lang="less">
	.search_result {
		width: 1200px;
		margin: 0 auto;
		margin-top: 24px;
	}

	.top {
		display: flex;
		align-items: center;
		font-size: 14px;

		.title {
			color: #999999;
		}

		.title:hover {
			cursor: pointer;
		}

		.img {
			display: flex;
			align-items: center;
			margin: 0 8px;

			img {
				width: 8px;
				height: 8px;
			}
		}

		.sub_title {
			display: flex;
			align-items: center;
			color: #333333;
		}
	}

	.all_house {
		margin-top: 24px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}


	.pagination {
		margin-top: 24px;
		display: flex;
		justify-content: flex-end;
	}
</style>
